.principle_M_cont{
    padding: 0px 75px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 60px;
    margin-top: 90px;
}
@media only screen and (max-width:550px){
    .principle_M_cont{
        padding: 0px 40px;
    }
}
.pContent p{
  text-align: left;
  color: black;
}
.pContent h2{
    font-family: 'Josefin Sans';
    font-weight: bold;
}
.pImage h5{
    font-family: 'Josefin Sans';
    margin-top: 15px;
}
.pContent h5{
    font-family: 'Josefin Sans';
   
}
.pContent {
 width: 1525px;
 margin-right: 40px;
 color: #364d59;
}
.pImage{
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: 0.3s;
}
.pImage:hover{
    scale: 1.04;
}

